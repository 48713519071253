
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { Dictionary } from 'vue-router/types/router'
import { Form as ElForm, Input } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { adminSmsToken, authCode } from '@/api/uaa/member'
import { particle } from '@/utils/particle'
import { sendSmsCode } from '@/api/uaa/oauth'

@Component({
  name: 'Login'
})
export default class extends Vue {
  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 6) {
      callback(new Error('密码需大于6位'))
    } else {
      callback()
    }
  }

  loginType = 'password'

  loginForm = {
    username: '',
    password: '',
    code: '',
    key: ''
  }

  loginPhoneForm = {
    phone: '',
    smsCode: ''
  }

  codeImg = ''
  codeImgLoading = true

  loginRules = {
    password: [{ validator: this.validatePassword, trigger: 'blur' }]
  }

  smsCodeLoading = false
  smsCodeTimer = 60

  passwordType = 'password'
  loading = false
  redirect?: string
  otherQuery: Dictionary<string> = {}

  @Watch('$route', { immediate: true })
  private onRouteChange(route: Route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query as Dictionary<string>
    if (query) {
      this.redirect = query.redirect
      this.otherQuery = this.getOtherQuery(query)
    }
  }

  mounted() {
    if (this.loginForm.username === '') {
      (this.$refs.username as Input).focus()
    } else if (this.loginForm.password === '') {
      (this.$refs.password as Input).focus()
    } else if (this.loginForm.code === '') {
      (this.$refs.code as Input).focus()
    }
    this.loadCodeImg()

    particle({
      el: '.login-container',
      num: 100,
      shape: 0.1
    })
  }

  showPwd() {
    if (this.passwordType === 'password') {
      this.passwordType = ''
    } else {
      this.passwordType = 'password'
    }
    this.$nextTick(() => {
      (this.$refs.password as Input).focus()
    })
  }

  handleLogin() {
    (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
      if (valid) {
        this.loading = true
        await UserModule.Login(this.loginForm).then(() => {
          this.$router.push({
            path: this.redirect || '/',
            query: this.otherQuery
          })
        }).catch(() => {})
        // Just to simulate the time of the request
        setTimeout(() => {
          this.loading = false
        }, 0.5 * 1000)
      } else {
        return false
      }
    })
  }
  async handleSmsLogin() {
    await UserModule.SmsLogin(this.loginPhoneForm).then(() => {
      this.$router.push({
        path: this.redirect || '/',
        query: this.otherQuery
      })
    }).catch(() => {
    })
    // Just to simulate the time of the request
    setTimeout(() => {
      this.loading = false
    }, 0.5 * 1000)
  }
  getOtherQuery(query: Dictionary<string>) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== 'redirect') {
        acc[cur] = query[cur]
      }
      return acc
    }, {} as Dictionary<string>)
  }

  changeCodeImg(){
    this.loadCodeImg()
  }

  private loadCodeImg(){
    this.codeImgLoading = true
    authCode().then(res=>{
      const { codeUrl, key } = res.data
      this.codeImg = codeUrl
      this.loginForm.key = key
    }).catch(()=>{
      this.codeImgLoading = false
    })
  }
  handleSendSmsCode(){
    sendSmsCode(this.loginPhoneForm).then(res=>{
      this.loadSmsCodeTimer()
      this.$message('消息发送成功');
    })
  }

  private loadSmsCodeTimer(){
    const interval = setInterval(() => {
      if(this.smsCodeTimer <= 0) {
        this.smsCodeLoading = false
        this.smsCodeTimer = 60
        //当倒计时结束，这里要清除定时器
        clearTimeout(interval);
      } else {
        this.smsCodeTimer--
      }
    }, 1000);
    this.smsCodeLoading = true
  }
}
